import { useWebBuilderDispatch, useWebBuilderState } from "context/webBuilder";
import React, { useEffect, useState } from "react";
import SearchBarHorizontal from "./SearchBarHorizontal";
import SearchBarVertical from "./SearchBarVertical";
import { useHistory } from "react-router-dom";
import { handleSearch } from "modules/WebBuilder-V2/helpers/handleSearch";
import { store } from "react-notifications-component";
import { useSBSState } from "context/global";
import Locale from "translations";

export default function HeroContent() {
  const { webBuilder, errors } = Locale;

  const { locale, allCountries } = useSBSState();
  const {
    hero,
    ourServices,
    content,
    style,
    has_marketplace,
    pagesData,
    active_modules,
  } = useWebBuilderState();
  const dispatch = useWebBuilderDispatch();
  const mainContent = hero?.mainContent;
  const [countriesLookup, setCountriesLookup] = useState([]);
  const history = useHistory();
  const preview = true;
  const [searchState, setSearchState] = useState({
    country: null,
    proudctType: null,
  });

  const layoutType = hero?.mainContent?.layout?.layoutType;
  const searchbar_position = hero?.mainContent?.searchbarPosition;

  const visibleServices = ourServices
    ? ourServices?.services
        ?.filter((service) => service?.visible && service?.type !== "packages")
        ?.map((service) => ({
          id: service?.type,
          name: webBuilder[service?.type],
        }))
    : [];

  const product_types = [
    { id: 1, name: webBuilder.hajj },
    { id: 2, name: webBuilder.umrah },
    { id: 3, name: webBuilder.umrahPlus },
    { id: 4, name: webBuilder.tourism },
    { id: 5, name: webBuilder.customServices },
    ...visibleServices,
  ];

  function handleSearchInputs(value, keyName) {
    if (
      keyName === "proudctType" &&
      (value.id === 1 || value.id === 2 || value.id === 3)
    ) {
      setCountriesLookup(() =>
        countriesLookup.filter((country) => country?.id === 966)
      );
    } else {
      setCountriesLookup(allCountries);
    }
    setSearchState({
      ...searchState,
      country:
        keyName === "proudctType" &&
        (value.id === 1 || value.id === 2 || value.id === 3)
          ? allCountries.find((country) => country?.id === 966)
          : searchState.country,
      [keyName]: value,
    });
  }

  function getSearchResults() {
    let searchResults = handleSearch(
      searchState?.country?.id,
      searchState?.proudctType,
      content,
      pagesData
    );
    if (searchResults?.length > 0) {
      dispatch({
        type: "addPackagesResult",
        payload: searchResults,
      });
      history.push(
        `/packages-results?country=${searchState?.country?.id}&type=${searchState?.proudctType?.id}`
      );
    } else {
      store.addNotification({
        title: errors.noDataFound,
        message: errors.noDataMatchSearchCriteria,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: { duration: 3000, onScreen: true, pauseOnHover: true },
      });
    }
  }
  useEffect(() => {
    if (allCountries) {
      setCountriesLookup(allCountries);
    }
  }, [allCountries]);

  function renderSearchForm() {
    return {
      center: (
        <SearchBarHorizontal
          product_types={product_types}
          countries={countriesLookup}
          searchState={searchState}
          handleSearchInputs={handleSearchInputs}
          handleSearch={getSearchResults}
          preview={preview}
          has_marketplace={has_marketplace && active_modules?.length > 0}
        />
      ),
      start: (
        <SearchBarVertical
          product_types={product_types}
          countries={countriesLookup}
          searchState={searchState}
          handleSearchInputs={handleSearchInputs}
          handleSearch={getSearchResults}
          preview={preview}
          has_marketplace={has_marketplace && active_modules?.length > 0}
        />
      ),
    };
  }

  return (
    <div
      className={`
        web-builder-hero-content 
        content-layout-${layoutType} 
        content-layout-${style?.theme}
        justify-content-${searchbar_position} 
        ${style.theme === "theme-2" ? "" : ""}
      `}
    >
      <p className="header h3">{mainContent?.title?.[locale]}</p>
      <p className="body">{mainContent?.body?.[locale]}</p>

      {renderSearchForm()?.[searchbar_position]}
    </div>
  );
}
