import React, { useState } from "react";
import { Collapse } from "reactstrap";
import { ReactComponent as PlusIcon } from "assets/images/webBuilder/plus.svg";
import { ReactComponent as MinusIcon } from "assets/images/webBuilder/minus.svg";
import ServicesContent from "modules/WebBuilder/shared/ServicesContent";
import { useSBSState } from "context/global";
import { useWebBuilderState } from "context/webBuilder";
import FAQTheme4 from "./Theme4";
import FAQTheme7 from "./Theme7";

function FAQs({ details }) {
  const { locale } = useSBSState();
  const { style } = useWebBuilderState();
  const { id, withImage, body, title, faqs, image, visible } = details;
  const [collapsedItems, setCollapsedItems] = useState([]);

  return (
    <>
      {visible ? (
        <div
          className="web-builder-faqs-container custom-container mt-3"
          id={id}
        >
          <div
            className={` ${withImage ? "faq-with-image" : "faq-column-view"}`}
          >
            {style.theme !== "theme-7" &&
              <div className="custom-container-top-section">
                <ServicesContent header={title} body={withImage ? "  " : body} />
              </div>
            }
            {(style.theme === "theme-1" ||
              style.theme === "theme-2" ||
              style.theme === "theme-3") && (
                <div className={`faq-detials`}>
                  <div className="faq-questions-container">
                    {faqs
                      ? faqs.map((que, index) => {
                        const isOpen = collapsedItems.includes(index);
                        const openCollapse = () => {
                          const updated = [...collapsedItems, index];
                          setCollapsedItems(updated);
                        };
                        const closeCollapse = () =>
                          setCollapsedItems(
                            collapsedItems.filter((i) => i !== index)
                          );

                        return (
                          <div className="faqs-question-wrapper" key={que.id}>
                            <div className="faqs-container-question">
                              <span> {que.question?.[locale]} </span>
                              {isOpen ? (
                                <button
                                  className="bg-transparent border-0 faq-btn"
                                  onClick={closeCollapse}
                                >
                                  <MinusIcon />
                                </button>
                              ) : (
                                <button
                                  className="bg-transparent border-0 faq-btn"
                                  onClick={openCollapse}
                                >
                                  <PlusIcon />
                                </button>
                              )}
                            </div>

                            <Collapse isOpen={isOpen}>
                              <p className="faqs-container-body">
                                {que.body?.[locale]}
                              </p>
                            </Collapse>
                          </div>
                        );
                      })
                      : null}
                  </div>

                  {withImage ? (
                    <>
                      {
                        <img
                          src={image}
                          className="faq-image"
                          alt="Faq-section"
                        />
                      }
                    </>
                  ) : null}
                </div>
              )}
          </div>
          {style.theme === "theme-4" && <FAQTheme4 faqs={faqs} />}
          {style.theme === "theme-7" && <FAQTheme7 faqs={faqs} title={title} image={image} />}
        </div>
      ) : null}
    </>
  );
}

export default FAQs;
