import ServicesContent from "modules/WebBuilder-V2/shared/ServicesContent";
import React from "react";
import { useWebBuilderState } from "context/webBuilder";

import { useSBSState } from "context/global";
import Theme1OurServices from "./theme-1/Wrapper";
import Theme2OurServices from "./theme-2/Wrapper";
import { isObject } from "lodash";
import OurServicesThemeFour from "./Theme4";
import OurServicesThemeSeven from "./Theme7";

function OurServices() {
  const { locale } = useSBSState();
  const { ourServices } = useWebBuilderState();
  const services = Array.isArray(ourServices?.services) ? ourServices?.services : [];

  const showServiceContent = ["with_images", "with_icons", "theme-4"];
  return (
    <>
      {showServiceContent.includes(ourServices?.ourServicesType) &&

        <div className="wb-our-service" id="our-service">
          {services?.filter((res) => res.visible).length > 0 ?
            <ServicesContent
              header={isObject(ourServices?.title) ? ourServices?.title?.[locale] : ourServices?.title}
              body={isObject(ourServices?.body) ? ourServices?.body?.[locale] : ourServices?.body}
            />
            : null
          }

          {ourServices?.ourServicesType === "with_images" ?
            <Theme1OurServices services={services} />
            :
            null
          }
          {ourServices?.ourServicesType === "with_icons" ?
            <Theme2OurServices services={services} />
            :
            null
          }
          {ourServices?.ourServicesType === "theme-4" ?
            <OurServicesThemeFour services={services} />
            :
            null
          }
        </div>
      }
      
      {ourServices?.ourServicesType === "theme-7" ?

        <OurServicesThemeSeven services={services} />
        :
        null
      }

    </>
  );
}

export default OurServices;
