import { ReactComponent as StarIcon } from "assets/images/webBuilder/star.svg";
import { ReactComponent as GoldenStarIcon } from "assets/images/webBuilder/golden-star.svg";
import { ReactComponent as LocationIcon } from "assets/images/webBuilder/location-outline-icon.svg";
import { useHistory } from "react-router-dom";
import Locale from "translations";
import { useWebBuilderState } from "context/webBuilder";
import { calcExchangeRatePrice } from "helpers/utils";
import { useSBSState } from "context/global";

export default function OverLayServiceCard(props) {
  const { exchageRates } = useWebBuilderState();
  const { currency: currentCurrency } = useSBSState();

  let {
    name,
    price,
    image,
    currency,
    stars,
    locationName,
    city,
    cardType,
    isNew,
    product_uuid,
    initialName,
  } = props;

  const { webBuilder } = Locale;

  const history = useHistory();
  const trimName = name ? name?.length > 16 ? `${name?.substr(0, 16)}...` : name : initialName;
  const hotelRating = +stars > 5 ? 5 : +stars;
  let exchageRate = exchageRates?.find(rate => rate?.from === currency)?.rate;
  let exchagedPrice = calcExchangeRatePrice(exchageRate, +price)


  return (
    <article
      className={`overlay-service-card ${isNew ? "empty-service" : ""}`}
      style={{ backgroundImage: image ? `url(${image})` : "" }}
      onClick={() => {
        if (product_uuid) {
          history.push(`/${cardType}/${product_uuid}`);
        }
      }}
      key={product_uuid}
    >
      {/* hotel details */}
      <div className="card-details">
        <div className="d-flex flex-column">
          {cardType === "hotels" ? (
            <div className="rate mb-2">
              {[...Array(5)].map((star, i) =>
                i < hotelRating ? (
                  <GoldenStarIcon key={i} />
                ) : (
                  <StarIcon key={i} />
                )
              )}
            </div>
          ) : null}

          <h4 title={name} className="hotel-name">
            {trimName}
          </h4>
          {cardType === "tours" ? (
            <div className="location">
              <LocationIcon />
              <span className="mx-1">
                {locationName}, {city}
              </span>
            </div>
          ) : null}
        </div>
        <div className="price">
          <span>{webBuilder.from}</span>
          <p className="amount">
            {exchagedPrice} <span className="currency">{exchageRate ? currentCurrency : currency || ""}</span>
          </p>
        </div>
      </div>
    </article>
  );
}
