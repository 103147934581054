import LocationMarkerIcon from "modules/WebBuilder-V2/shared/icons/LocationMarker";
import StarIcon from "modules/WebBuilder-V2/shared/icons/Star";
import PackageTypeIcon from "modules/WebBuilder-V2/shared/icons/PackageTypeIcon";
import CalendarIcon from "modules/WebBuilder-V2/shared/icons/Calendar";
import ArrowRightIcon from "modules/WebBuilder-V2/shared/icons/ArrowRight";
import { Link } from "react-router-dom";
import moment, { now } from "moment";
import Locale from "translations";
import { useSBSState } from "context/global";
import { useWebBuilderState } from "context/webBuilder";
import { calcExchangeRatePrice } from "helpers/utils";

export default function ThemeSevenPackageCard(props) {
	const {
		// onClick,
		image,
		name,
		departure_date_at,
		return_date_at,
		stars,
		product_uuid,
		hotelName,
		price,
		locationName,
		initialName,
		currency,
	} = props;

	const { webBuilder } = Locale;

	const { currency: currentCurrency } = useSBSState();
	const { exchageRates } = useWebBuilderState();
	let exchageRate = exchageRates?.find(
		(rate) => rate?.from === currency
	)?.rate;
	let exchagedPrice = calcExchangeRatePrice(exchageRate, +price);
	const packageUrl = `/package/${product_uuid}`;

	return (
		<div className="theme_package_card">
			<div className="image">

				<Link to={packageUrl}>
					<img src={image} alt={name} onError={(e) => {
						e.target.onerror = null;
						e.target.src = "https://back-sbs.staging.safa.asia/api/v1/files/fetch/1847cef6-e5c0-40bb-b1dd-d2dc7827cd58";
					}} />
				</Link>

			</div>
			<div className="content">
				<div className="location">
					<p className="destination_name">
						<LocationMarkerIcon />
						<span>{locationName}</span>
						{/* <span className="mx-1">/</span>
						<span>Rome</span> */}
					</p>
				</div>
				<div className="package_name">
					<h5 className="package_title">
						<a href="/">{name || initialName}</a>
					</h5>
					<div className="package_rating">
						{[...Array(5)].map((i, index) =>
							index < stars ? (
								<StarIcon fill="#F5B950" width="16" height="14" />
							) : (
								<StarIcon fill="#DDDCDC" width="16" height="14" />
							)
						)}
					</div>
				</div>
				<div className="package_meta">
					<div className="hotel_name">
						<PackageTypeIcon />
						<span className="mx-1"></span>
						<span>{hotelName || "-"}</span>
					</div>
					<div className="package_date">
						<CalendarIcon />
						<span className="mx-1"></span>
						<span>
							{`${moment(departure_date_at || now()).format(
								"DD MMM YYYY"
							)} - ${moment(return_date_at || now()).format("DD MMM YYYY")}`}
						</span>
					</div>
				</div>
				<div className="package_footer">
					<div className="pricing">
						<p className="from_text">{webBuilder.from}</p>
						<p className="actual_price">{exchagedPrice} {" "}
							<span>{exchageRate ? currentCurrency : currency || ""}</span>
						</p>
					</div>
					<div className="cta">
						<Link to={packageUrl} className="theme_btn">
							<span className="btn_content">
								<ArrowRightIcon className="mirror-rtl" />
							</span>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
}
