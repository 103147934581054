
import { Link } from "react-router-dom";

export default function HeaderThemeFour({ nav, logoImage, contactButton }) {

  return (
    <header className="wb-header">
      {/* logo */}
      <div className="logo">
        <Link to="/">
          <img src={logoImage} alt="Logo" />
        </Link>
      </div>

      {/* nav */}
      <div className="nav-container">
        {nav}
        {contactButton}
      </div>
    </header>
  );
}
