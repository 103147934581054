import React, { useState } from "react";
import { Collapse, Modal, ModalBody, ModalHeader } from "reactstrap";
import { ReactComponent as ExternalTransportationIcon } from "assets/images/market-place/itinerary/itinerary-external-transportation.svg";
import { ReactComponent as InternalTransportationIcon } from "assets/images/market-place/itinerary/itinerary-internal-transportation.svg";
import { ReactComponent as HotelIcon } from "assets/images/market-place/itinerary/itinerary-hotel.svg";
import { ReactComponent as AttractionIcon } from "assets/images/market-place/itinerary/itinerary-attractions.svg";
import moment, { now } from "moment";
import Locale from "translations";
import { useSBSState } from "context/global";
import { unLocalizeDate } from "helpers/utils";

export default function ProductItinerary({ itineraryData }) {
  const itineraryElementsIds = {
    external: 1,
    internal: 2,
    visa: 4,
    insurance: 5,
    hotel: 6,
    sightseeing: 7,
    otherServices: 8,
  };

  const packageStartDate = moment(itineraryData[0]?.start_period_date);
  const packageEndDate = moment(
    itineraryData[itineraryData.length - 1]?.end_period_date
  );

  function generateDaysList() {
    let daysList = [];
    for (
      var current = packageStartDate;
      current <= packageEndDate;
      current.add(1, "d")
    ) {
      daysList.push(current.format("YYYY-MM-DD"));
    }
    return daysList;
  }
  let daysList = generateDaysList();
  const [detailsModalData, setDetailsModalData] = useState({
    isOpen: false,
    description: "",
    typeId: null,
  });

  function toggleDetailsModal(description, typeId) {
    setDetailsModalData({
      isOpen: !detailsModalData.isOpen,
      description,
      typeId,
    });
  }

  return (
    <div>
      {itineraryData?.map((item, index) => {
        return (
          <ProductItineraryItem
            key={index}
            itineraryIndex={index}
            itineraryItem={item}
            packageStartDate={packageStartDate}
            packageEndDate={packageEndDate}
            daysList={daysList}
            itineraryElementsIds={itineraryElementsIds}
            toggleDetailsModal={toggleDetailsModal}
            itineraryCount={itineraryData.length}
          />
        );
      })}

      {detailsModalData?.isOpen ? (
        <MoreDetailsModal
          detailsModalData={detailsModalData}
          toggle={toggleDetailsModal}
          itineraryElementsIds={itineraryElementsIds}
        />
      ) : null}
    </div>
  );
}

export function ProductItineraryItem({
  itineraryItem,
  daysList,
  itineraryIndex,
  itineraryElementsIds,
  toggleDetailsModal,
  itineraryCount,
}) {
  const { locale } = useSBSState();
  const { packages, marketPlace } = Locale;
  const [isDayOpen, setIsDayOpen] = useState(
    itineraryIndex === 0 ? true : false
  );
  const pointStartDate = itineraryItem?.start_period_date;
  const pointEndDate = itineraryItem?.end_period_date;

  moment.locale(locale || "en");

  function viewItineraryElements() {
    const elementsCount = itineraryItem?.items?.length;
    const hotelWithExternalItems =
      elementsCount > 1 &&
      itineraryItem?.items?.filter(
        (item) =>
          item?.item?.id === itineraryElementsIds.hotel ||
          item?.item?.id === itineraryElementsIds.external
      ).length > 1;

    const isFirstItineraryItemHasHotel =
      itineraryIndex === 0 &&
      itineraryItem?.items?.filter(
        (item) => item?.item?.id === itineraryElementsIds.hotel
      ).length > 0;

    const isLastItineraryItemHasHotel =
      itineraryIndex === itineraryCount - 1 &&
      itineraryItem?.items?.filter(
        (item) => item?.item?.id === itineraryElementsIds.hotel
      ).length > 0;

    return itineraryItem?.items?.map((element, index) => {
      switch (element?.item?.id) {
        case 1:
          return (
            <ExternalTransportationItinerary
              key={element?.id}
              flightData={element}
              toggleDetailsModal={toggleDetailsModal}
            />
          );
        case 2:
          return (
            <InternalTransportationItinerary
              key={element?.id}
              toggleDetailsModal={toggleDetailsModal}
              internalData={element}
            />
          );
        case 6:
          return (
            <HotelItinerary
              key={element?.id}
              hotelIndex={index}
              elementsCount={elementsCount}
              hotelData={element}
              hotelWithExternalItems={hotelWithExternalItems}
              toggleDetailsModal={toggleDetailsModal}
              isFirstItineraryItemHasHotel={isFirstItineraryItemHasHotel}
              isLastItineraryItemHasHotel={isLastItineraryItemHasHotel}
            />
          );
        case 7:
          return (
            <AttractionItinerary
              key={element?.id}
              attractionData={element}
              toggleDetailsModal={toggleDetailsModal}
            />
          );
        default:
          return null;
      }
    });
  }

  return (
    <div className="product-itinerary-container">
      <div
        className="product-itinerary-title pointer"
        onClick={() => setIsDayOpen(!isDayOpen)}
      >
        <h3>
          {pointStartDate === pointEndDate ? (
            <>
              {packages.day}
              <span className="mx-2">
                {daysList?.findIndex(
                  (day) => unLocalizeDate(day) === pointStartDate
                ) + 1}
              </span>
            </>
          ) : (
            <span>
              {marketPlace.From} {packages.day}{" "}
              {daysList?.findIndex(
                (day) => unLocalizeDate(day) === pointStartDate
              ) + 1}{" "}
              {marketPlace.To}{" "}
              {daysList?.findIndex(
                (day) => unLocalizeDate(day) === pointEndDate
              ) + 1}
              ,
            </span>
          )}
          {/* {pointStartDate === pointEndDate ? "same" : "not same"} */}
          {pointStartDate === pointEndDate
            ? moment(pointStartDate).format("dddd DD MMMM")
            : `${moment(pointStartDate).format("dddd DD MMMM")} - ${moment(
                pointEndDate
              ).format("dddd DD MMMM")}`}
        </h3>
        <button className="btn">
          {isDayOpen ? (
            <i className="fas fa-chevron-down" />
          ) : (
            <i className="fas fa-chevron-up" />
          )}
        </button>
      </div>

      <Collapse isOpen={isDayOpen}>
        <div className="itinerary-timeline">{viewItineraryElements()}</div>
      </Collapse>
    </div>
  );
}

export function ExternalTransportationItinerary({
  flightData,
  toggleDetailsModal,
}) {
  const { inventory, packages } = Locale;
  return (
    <div className="itinerary-segment external">
      <div className="segment-point">
        <span></span>
      </div>
      <span className="segment-time">
        {moment(flightData?.itemable?.departure_at).utc().format("hh:mm A")}
      </span>
      <div className="segment-details">
        <div className="d-flex align-items-center">
          <span className="icon">
            <ExternalTransportationIcon />
          </span>
          <h4 className="font-weight-bold mx-3">
            {packages.globalTransportation}
          </h4>
        </div>
        <div>
          <p>
            <span className="segment-time me-2">
              {" "}
              {moment(flightData?.itemable?.departure_at)
                .utc()
                .format("hh:mm A")}{" "}
            </span>
            <span className="font-weight-bold">
              {inventory.messages.departure} :
            </span>{" "}
            {flightData?.itemable?.from_port?.name}
          </p>
          <p className="mt-2">
            <span className="segment-time me-2">
              {moment(flightData?.itemable?.arrival_at).utc().format("hh:mm A")}{" "}
            </span>
            <span className="font-weight-bold">
              {inventory.messages.arrival} :
            </span>{" "}
            {flightData?.itemable?.to_port?.name}
          </p>
        </div>

        {/* details button */}
        {flightData?.itemable?.description ? (
          <button
            className="btn more-details-btn"
            onClick={() =>
              toggleDetailsModal(
                flightData?.itemable?.description,
                flightData?.item?.id
              )
            }
          >
            {packages.moreDetails}
          </button>
        ) : null}
      </div>
    </div>
  );
}

export function InternalTransportationItinerary({
  internalData,
  toggleDetailsModal,
}) {
  const { productsBuilder, packages } = Locale;

  return (
    <div className="itinerary-segment internal">
      <div className="segment-point">
        <span></span>
      </div>

      <span className="segment-time">
        {moment(internalData?.itemable?.pickup_time).utc().format("hh:mm A")}
      </span>

      <div className="segment-details">
        <div className="d-flex align-items-center">
          <span className="icon">
            <InternalTransportationIcon />
          </span>
          <h4 className="font-weight-bold mx-3">
            {productsBuilder.internalTransportations}
          </h4>
        </div>
        <div>
          {/* pick up */}
          <p>
            <span className="segment-time me-2">
              {moment(internalData?.itemable?.pickup_time)
                .utc()
                .format("hh:mm A")}{" "}
            </span>
            <span className="font-weight-bold">Pick-up :</span>{" "}
            {internalData?.itemable?.pickup_point}
          </p>
          {/* drop off */}
          <p className="mt-2">
            <span className="segment-time me-2">
              {moment(internalData?.itemable?.drop_off_time)
                .utc()
                .format("hh:mm A")}{" "}
            </span>
            <span className="font-weight-bold">Drop-off :</span>{" "}
            {internalData?.itemable?.drop_off_point}
          </p>
        </div>
        {/* details button */}
        {internalData?.itemable?.description ? (
          <button
            className="btn more-details-btn"
            onClick={() =>
              toggleDetailsModal(
                internalData?.itemable?.description,
                internalData?.item?.id
              )
            }
          >
            {packages.moreDetails}
          </button>
        ) : null}
      </div>
    </div>
  );
}

export function HotelItinerary({
  hotelData,
  elementsCount,
  hotelIndex,
  hotelWithExternalItems,
  toggleDetailsModal,
  isFirstItineraryItemHasHotel,
  isLastItineraryItemHasHotel,
}) {
  const { marketPlace, productsBuilder, inventory, packages } = Locale;
  const isCheckOut = hotelIndex < 1;
  const showHotelDetails =
    hotelWithExternalItems ||
    isFirstItineraryItemHasHotel ||
    isLastItineraryItemHasHotel ||
    elementsCount > 1;
  const isCheckInDate =
    (hotelWithExternalItems && !isCheckOut) || isFirstItineraryItemHasHotel;
  const isCheckOutDate =
    (hotelWithExternalItems && isCheckOut) || isLastItineraryItemHasHotel;

  return (
    <div className="itinerary-segment hotel">
      {/* first or last */}
      {showHotelDetails ? (
        <>
          <div className="segment-point">
            <span></span>
          </div>

          <span
            className={`segment-time ${
              !isFirstItineraryItemHasHotel &&
              !isLastItineraryItemHasHotel &&
              !hotelWithExternalItems
                ? "opacity-0"
                : ""
            } `}
          >
            {moment(
              isCheckOutDate
                ? hotelData?.itemable?.check_out_at
                : isCheckInDate
                ? hotelData?.itemable?.check_in_at
                : now()
            )
              .utc()
              .format("hh:mm A")}
          </span>
        </>
      ) : null}

      <div className="segment-details">
        <div className="d-flex align-items-center">
          <span className="icon">
            <HotelIcon />
          </span>
          <h4 className="font-weight-bold mx-3">{inventory.messages.hotels}</h4>
        </div>

        {showHotelDetails ? (
          <div className="d-flex align-items-baseline">
            {hotelWithExternalItems ||
            isFirstItineraryItemHasHotel ||
            isLastItineraryItemHasHotel ? (
              <span className="segment-time me-2 mt-1">
                {moment(
                  isCheckOutDate
                    ? hotelData?.itemable?.check_out_at
                    : isCheckInDate
                    ? hotelData?.itemable?.check_in_at
                    : now()
                )
                  .utc()
                  .format("hh:mm A")}{" "}
              </span>
            ) : null}

            <div>
              {/* show check in or checkout */}
              <span className="font-weight-bold">
                {(hotelWithExternalItems && isCheckOut) ||
                isLastItineraryItemHasHotel
                  ? marketPlace.messages.checkOut
                  : (hotelWithExternalItems && !isCheckOut) ||
                    isFirstItineraryItemHasHotel
                  ? marketPlace.messages.checkIn
                  : ""}
              </span>{" "}
              {hotelData?.itemable?.hotel?.name}
              {(hotelWithExternalItems && !isCheckOut) ||
              isFirstItineraryItemHasHotel ? (
                <ul className="info-list">
                  <li>
                    {productsBuilder.Nonights} :{" "}
                    {hotelData?.itemable?.night_count}{" "}
                  </li>
                  <li>
                    {productsBuilder.class} :{" "}
                    {hotelData?.itemable?.hotel?.rating} {inventory.Stars}
                  </li>
                </ul>
              ) : null}
            </div>
          </div>
        ) : (
          <div>
            <span className="font-weight-bold">{packages.residenceAt} :</span>{" "}
            {hotelData?.itemable?.hotel?.name}
          </div>
        )}

        {/* details button */}
        {hotelData?.itemable?.description ? (
          <button
            className="btn more-details-btn"
            onClick={() =>
              toggleDetailsModal(
                hotelData?.itemable?.description,
                hotelData?.item?.id
              )
            }
          >
            {packages.moreDetails}
          </button>
        ) : null}
      </div>
    </div>
  );
}

export function AttractionItinerary({ attractionData, toggleDetailsModal }) {
  const { productsBuilder, packages } = Locale;

  return (
    <div className="itinerary-segment attraction">
      <div className="segment-point">
        <span></span>
      </div>
      <span className="segment-time">
        {moment(attractionData?.itemable?.date).utc().format("hh:mm A")}{" "}
      </span>

      <div className="segment-details">
        <div className="d-flex align-items-center">
          <span className="icon">
            <AttractionIcon />
          </span>
          <h4 className="font-weight-bold mx-3">
            {productsBuilder.attractionLandmark}
          </h4>
        </div>

        <div className="d-flex align-items-baseline">
          <span className="segment-time me-2">
            {" "}
            {moment(attractionData?.itemable?.date)
              .utc()
              .format("hh:mm A")}{" "}
          </span>
          <div>
            <span className="font-weight-bold">
              {attractionData?.itemable?.name?.en}
            </span>
            {attractionData?.itemable?.services &&
            attractionData?.itemable?.services?.length > 0 ? (
              <ul className="info-list">
                {attractionData?.itemable?.services?.map((service, index) => (
                  <li key={`${service}-${index}`}>{service}</li>
                ))}
              </ul>
            ) : null}
          </div>
        </div>
        {/* details button */}
        {attractionData?.itemable?.description ? (
          <button
            className="btn more-details-btn"
            onClick={() =>
              toggleDetailsModal(
                attractionData?.itemable?.description,
                attractionData?.item?.id
              )
            }
          >
            {packages.moreDetails}
          </button>
        ) : null}
      </div>
    </div>
  );
}

export function MoreDetailsModal({
  detailsModalData,
  toggle,
  itineraryElementsIds,
}) {
  const { packages, inventory, productsBuilder, webBuilder } = Locale;
  function headerEle() {
    switch (detailsModalData.typeId) {
      case itineraryElementsIds.external:
        return (
          <div className="d-flex align-items-center external rounded-circle">
            <span className="icon d-flex align-items-center justify-content-center">
              <ExternalTransportationIcon />
            </span>
            <h4 className="font-weight-bold mx-3">
              {packages.globalTransportation}
            </h4>
          </div>
        );
      case itineraryElementsIds.hotel:
        return (
          <div className="d-flex align-items-center hotel rounded-circle">
            <span className="icon d-flex align-items-center justify-content-center">
              <HotelIcon />
            </span>
            <h4 className="font-weight-bold mx-3">
              {inventory.messages.hotels}
            </h4>
          </div>
        );
      case itineraryElementsIds.sightseeing:
        return (
          <div className="d-flex align-items-center attraction rounded-circle">
            <span className="icon d-flex align-items-center justify-content-center">
              <AttractionIcon />
            </span>
            <h4 className="font-weight-bold mx-3">
              {productsBuilder.attractionLandmark}
            </h4>
          </div>
        );
      case itineraryElementsIds.internal:
        return (
          <div className="d-flex align-items-center internal rounded-circle">
            <span className="icon d-flex align-items-center justify-content-center">
              <InternalTransportationIcon />
            </span>
            <h4 className="font-weight-bold mx-3">
              {productsBuilder.internalTransportations}
            </h4>
          </div>
        );
      default:
        break;
    }
  }
  return (
    <Modal isOpen={detailsModalData.isOpen}>
      <ModalHeader toggle={toggle} className="align-items-center">
        <div className="product-itinerary-container m-0 border-0 py-2">
          {headerEle()}
        </div>
      </ModalHeader>
      <ModalBody>
        <h5 className="font-weight-bold">{webBuilder.description}</h5>
        <p className="mt-2">{detailsModalData?.description}</p>
      </ModalBody>
    </Modal>
  );
}
