import PackageBreadCrumb from "./breadCrumb";
import SeachResult from "./searchResult";

export default function PackageSearch() {


  return (
    <div
      className="public-page-search web-builder-preview web-builder-content p-0"
      id="main-web-builder"
    >
      {/* <HeroHeader /> */}
      <PackageBreadCrumb />
      <SeachResult />
    </div>
  );
}
