import { ReactComponent as LocationIcon } from "assets/images/webBuilder/location-outline-icon.svg";
import Locale from "translations";
import Carousel from "react-multi-carousel";
import ButtonGroup from "modules/WebBuilder-V2/shared/CarouselButtonGroup";
import { useSBSState } from "context/global";
import { calcExchangeRatePrice } from "helpers/utils";
import { useWebBuilderState } from "context/webBuilder";
import { Link } from "react-router-dom";

export default function ToursTheme4({ items }) {
  const { webBuilder } = Locale;
  const { locale, currency: currentCurrency } = useSBSState();
  const { exchageRates } = useWebBuilderState();

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 567 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 567, min: 0 },
      items: 1,
    },
  };

  const showArrows = items.length > responsive.desktop.items;

  return (
    <Carousel
      responsive={responsive}
      itemClass={"service-slide"}
      className="tours-container"
      slidesToSlide={1}
      arrows={false}
      keyBoardControl={true}
      customButtonGroup={showArrows ? <ButtonGroup /> : null}
      renderButtonGroupOutside={true}
      removeArrowOnDeviceType={["tablet", "mobile"]}
      renderArrowsWhenDisabled={true}
      infinite={true}
      rtl={locale === "ar"}
    >
      {items.map((tour, index) => {
        let exchageRate = exchageRates?.find(
          (rate) => rate?.from === tour?.currency
        )?.rate;
        let exchagedPrice = calcExchangeRatePrice(exchageRate, +tour?.price);
        return (
          <div key={index} className={`card-holder`}>
            <div className={`service-img`}>
              {tour?.image ? (
                <img src={tour?.image} alt="our package" />
              ) : (
                <img
                  src="https://back-sbs.dev.safa.asia/api/v1/files/fetch/476c2074-0c5e-4e82-a891-d5099530f7e6"
                  alt="Safa Visa"
                />
              )}
            </div>

            <div className="card-caption-holder">
              <h3 className="card-title mb-0">{tour?.name}</h3>

              {/* Hotel Location */}
              <div className="pb-2">
                <LocationIcon className="location-icon" />
                <span className="location">{tour?.locationName} {tour?.city ? ` - ${tour?.city}` : null}</span>
              </div>

              {/* Card Action and Pricing */}
              <div className="card-action-holder">
                <div className="card-price">
                  <p className="label">{webBuilder?.startFrom}</p>

                  <div className="d-flex align-items-center">
                    <p className="price">{exchagedPrice}</p>
                    <p className="ml-1">
                      {exchageRate ? currentCurrency : tour?.currency || ""}/
                      <span>{webBuilder?.night}</span>
                    </p>
                  </div>
                </div>

                <Link
                  to={tour?.product_uuid ? `/tours/${tour?.product_uuid}` : "/"}
                  className="btn book-btn"
                >
                  {webBuilder?.bookNow}
                </Link>
              </div>
            </div>
          </div>
        );
      })}
    </Carousel>
  );
}
