import React, { useCallback, useEffect, useRef, useState } from "react";
import SearchResultData from "./searchResultData";
import SearchFilter from "./filter";
import { useWebBuilderState } from "context/webBuilder";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { handleSearch } from "modules/WebBuilder-V2/helpers/handleSearch";
import { ReactComponent as FilterSearchIcon } from "assets/images/webBuilder/filter-search.svg";
import Locale from "translations";
import { calcExchangeRatePrice } from "helpers/utils";

export default function SeachResult() {
  const { packagesResult, content, ourServices, pagesData, exchageRates } = useWebBuilderState();
  const { webBuilder } = Locale;

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const country = searchParams.get("country");
  const productType = searchParams.get("type");
  const initalResultData = useRef();
  // states
  const [resultData, setResultData] = useState(null);
  const [initalPrices, setInitalPrices] = useState({ min: 0, max: 0 });
  const [filter, setFilter] = useState({
    packageType: [],
    fromPriceRange: 0,
    toPriceRange: 0,
    starRating: [],
    sortBy: null,
  });

  const visibleServices = ourServices
    ? ourServices?.services
      ?.filter((service) => service?.visible)
      ?.map((service) => ({
        id: service?.type,
        name: webBuilder[service?.type],
      }))
    : [];



  const product_types = [
    { id: 1, name: webBuilder.hajj },
    { id: 2, name: webBuilder.umrah },
    { id: 3, name: webBuilder.umrahPlus },
    { id: 4, name: webBuilder.tourism },
    { id: 5, name: webBuilder.customServices },
    ...visibleServices,
  ];
  const selectedproductType = product_types.find(type => type?.id === productType);

  // search with params
  const getSearchResults = useCallback(() => {
    let searchResults = [];
    // check there is search result in context
    if (packagesResult) {
      searchResults = packagesResult;
    }
    searchResults = handleSearch(+country, selectedproductType, content, pagesData)?.sort(
      (a, b) => a.price - b.price
    );
    // let maxPrice = Math.max(...searchResults?.map((result) => result?.price));
    let maxPrice = Math.max(...searchResults?.map((item) => {
      let exchageRate = exchageRates?.find(rate => rate?.from === item.currency)?.rate;
      let exchangedPrice = calcExchangeRatePrice(exchageRate, +item?.price, true);
      return +exchangedPrice;
    }));


    // let exchageRate = exchageRates?.find(rate => rate?.from === item.currency)?.rate;
    // let exchagedPrice = calcExchangeRatePrice(exchageRate, +item?.price)
    setInitalPrices({ min: 0, max: maxPrice });
    setResultData(searchResults);
    initalResultData.current = searchResults;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  useEffect(() => {
    getSearchResults();
  }, [content, getSearchResults, packagesResult]);

  function resetFilters() {
    setFilter({
      packageType: [],
      fromPriceRange: 0,
      toPriceRange: initalPrices.max,
      starRating: [],
      sortBy: null,
    });
  }
  // filter by prices
  function filterByPrice() {
    if (initalResultData.current?.length > 0 && filter.toPriceRange > 0) {
      let filteredPrices = initalResultData.current?.filter((item) => {
        let exchageRate = exchageRates?.find(rate => rate?.from === item.currency)?.rate;
        let exchangedPrice = calcExchangeRatePrice(exchageRate, +item?.price, true);
        return (
          +exchangedPrice >= +filter?.fromPriceRange &&
          +exchangedPrice <= +filter.toPriceRange
        );
      });
      setResultData(filteredPrices);
    } else {
      setResultData(initalResultData.current);
    }
  }

  // filter by prices
  // useEffect(() => {
  //   // (proudctType === "hotels" || proudctType === "packages") &&filter.starRating.length > 0
  //   if (initalResultData.current?.length > 0 && filter.toPriceRange > 0) {
  //     let filteredPrices = initalResultData.current?.filter((item) => {
  //       return (
  //         item.price >= filter?.fromPriceRange &&
  //         item?.price <= filter.toPriceRange
  //       );
  //       // productType.includes("tour") ? +item?.stars === 0 : +item?.stars > 0
  //     });
  //     setResultData(filteredPrices);
  //   } else {
  //     setResultData(initalResultData.current);
  //   }
  // }, [
  //   filter?.fromPriceRange,
  //   filter.starRating,
  //   filter.toPriceRange,
  //   productType,
  // ]);



  //filter by stars
  
  useEffect(() => {
    //(proudctType === "hotels" || proudctType === "packages") &&
    if (filter.starRating.length > 0 && !productType.includes("tour")) {
      let filteredRate = initalResultData.current?.filter(
        (item) =>
          item?.stars > 0 &&
          filter?.starRating?.includes(item?.stars?.toString())
      );
      if (filter.starRating.length === 0) {
        setResultData(initalResultData.current);
        return;
      }
      setResultData(filteredRate);
    } else {
      setResultData(initalResultData.current);
    }
  }, [filter?.starRating, productType]);


  //filter by package type
  useEffect(() => {
    if (filter.packageType.length > 0) {
      let filteredRate = initalResultData.current?.filter((item) =>
        filter.packageType.includes(item?.product_type.name)
      );

      setResultData(filteredRate);
    } else {
      setResultData(initalResultData.current);
    }
  }, [filter?.packageType, productType]);

  function handleSort(sortType) {
    if (sortType) {
      let sortedPrice;
      if (sortType === "asc") {
        sortedPrice = [...initalResultData.current]?.sort(
          (a, b) => a.price - b.price
        );
      } else {
        sortedPrice = [...initalResultData.current]?.sort(
          (a, b) => b.price - a.price
        );
      }
      setResultData(sortedPrice);
    } else {
      setResultData(initalResultData.current);
    }
  }

  function toggleOpenFilter() {
    const filterEle = document.getElementsByClassName("search-filter")?.[0];
    if (filterEle) {
      filterEle.classList.contains("opened")
        ? filterEle.classList.remove("opened")
        : filterEle.classList.add("opened");
    }
  }

  return (
    <div className="all-search-result">
      {ourServices &&
        <>
          <div className="row align-items-baseline">
            <div className="col-md-3">
              <SearchFilter
                filter={filter}
                setFilter={setFilter}
                initalPrices={initalPrices}
                length={resultData?.length}
                resetFilters={resetFilters}
                // product_types={product_types}
                productType={productType}
                toggleOpenFilter={toggleOpenFilter}
                filterByPrice={filterByPrice}
              />
            </div>
            <div className="col-md-9">
              <SearchResultData
                resultData={resultData}
                initalPrices={initalPrices}
                setInitalPrices={setInitalPrices}
                productType={selectedproductType}
                filter={filter}
                setFilter={setFilter}
                handleSort={handleSort}
                searchCountryId={country}
              />
            </div>
          </div>

          <button
            className="btn filter-mobile-btn shadow-none"
            onClick={toggleOpenFilter}
          >
            <FilterSearchIcon />
          </button>
        </>
      }
    </div>
  );
}
