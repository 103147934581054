import { useWebBuilderState } from 'context/webBuilder';
import ToursCarouselOverLayView from 'modules/WebBuilder-V2/Components/Content/ToursService/ToursCarouselOverLayView';
import ToursCarouselView from 'modules/WebBuilder-V2/Components/Content/ToursService/ToursCarouselView';
import { ServiceCardDetails } from 'modules/WebBuilder-V2/shared/ServiceCardDetails';



export default function CustomPageTours({ details, title }) {
  const { style } = useWebBuilderState();
  const { id, items, tours_type } = details;

  function renderToursViews() {
    return {
      "theme-1": <ToursCarouselView
        items={items}
      />,
      "theme-2": <ToursCarouselOverLayView
        items={items}
      />,
      "theme-3":
        <div className="slider-edit-container">
          {items.map((props, index) => (
            <ServiceCardDetails {...props} itemType="tour" key={props?.id || index} />
          ))}
        </div>
    }
  }


  return (
    <>
      <h3 className="section-title">
        {title}
      </h3>
      <div className="web-builder-content-tours" id={id}>

        <div className="hotels-detials services-carousel-container">
          {items && renderToursViews()?.[style?.theme || tours_type]}
        </div>
      </div>
    </>
  )
}
