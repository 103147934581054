import { useWebBuilderState } from "context/webBuilder";
import React from "react";
import CenteredFooter from "./CenteredFooter";
import FooterTempTwo from "./FooterTempTwo";

import { ReactComponent as FacebookIcon } from "assets/images/webBuilder/Facebook.svg";
import { ReactComponent as XIcon } from "assets/images/webBuilder/XIcon.svg";
import { ReactComponent as YouTubeIcon } from "assets/images/webBuilder/youtube.svg";
import { ReactComponent as LinkedInIcon } from "assets/images/webBuilder/linkedin.svg";
import { ReactComponent as InstagramIcon } from "assets/images/webBuilder/instagram.svg";
import { ReactComponent as SnapchatIcon } from "assets/images/webBuilder/snapchat-icon.svg";
import { ReactComponent as TiktokIcon } from "assets/images/webBuilder/tiktok-icon.svg";
import FooterThemeFour from "./Theme-4";
import FooterThemeSeven from "./Theme-7";

export default function Footer() {
  const { footer: footerDetails, style } = useWebBuilderState();
  const footer_position = footerDetails?.footerPosition;

  const socialMediaIcons = {
    facebook: <FacebookIcon />,
    twitter: <XIcon />,
    youtube: <YouTubeIcon />,
    instagram: <InstagramIcon />,
    linkedin: <LinkedInIcon />,
    snapchat: <SnapchatIcon />,
    tiktok: <TiktokIcon />,
  };

  function renderFooter() {
    return {
      center: <CenteredFooter socialMediaIcons={socialMediaIcons} />,

      between: <FooterTempTwo socialMediaIcons={socialMediaIcons} />,
    };
  }

  return (
    <footer className="footer-container">
      {(style?.theme === "theme-1" ||
        style?.theme === "theme-2" ||
        style?.theme === "theme-3") &&
        renderFooter()?.[footer_position]}

      {style?.theme === "theme-4" && (
        <FooterThemeFour socialMediaIcons={socialMediaIcons} />
      )}
      {style?.theme === "theme-7" && (
        <FooterThemeSeven socialMediaIcons={socialMediaIcons} />
      )}
    </footer>
  );
}
