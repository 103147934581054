import LocationMarkerIcon from 'modules/WebBuilder-V2/shared/icons/LocationMarker';
import { useWebBuilderState } from 'context/webBuilder';
import { useSBSState } from 'context/global';
import { calcExchangeRatePrice } from 'helpers/utils';
import { Link } from 'react-router-dom';
import Locale from 'translations';



export default function ThemeSevenTourCard(props) {

  let {
    name,
    price,
    image,
    currency,
    locationName,
    city,
    product_uuid,
    initialName,
    className
  } = props;

  const { webBuilder } = Locale;
  const { currency: currentCurrency } = useSBSState();
  const { exchageRates } = useWebBuilderState();
  let exchageRate = exchageRates?.find(
    (rate) => rate?.from === currency
  )?.rate;
  let exchagedPrice = calcExchangeRatePrice(exchageRate, +price);

  const tourUrl = `/tours/${product_uuid}`;

  return (
    <Link to={tourUrl}>
      <div className={`theme_tour_card ${props?.cardStyleType || ''} ${className || ""}`}>
        <div className="image">
          <img src={image} alt={name} onError={(e) => {
            e.target.onerror = null;
            e.target.src = "https://back-sbs.staging.safa.asia/api/v1/files/fetch/0b342f56-0067-4e51-a252-dc06a631b308";
          }} />

        </div>

        <div className="content_overlay">
          <div className="text_wrapper">
            <h5 className="title">
              {/* <a href="/">Cairo Tower</a> */}
              <span>{name || initialName}</span>
            </h5>
            <p className='destination_name'>
              <LocationMarkerIcon />
              <span>{locationName}</span>
              {city &&
                <>
                  <span className='mx-1'>/</span>
                  <span>{city}</span>
                </>
              }
            </p>
          </div>
          <div className="price_wrapper">
            <p className="from_text">{webBuilder.from}</p>
            <p className="actual_price">
              {exchagedPrice} <span>{exchageRate ? currentCurrency : currency || ""}</span>
            </p>
          </div>
        </div>
      </div>
    </Link>
  )
}