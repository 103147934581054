import { useWebBuilderState } from 'context/webBuilder';
import FacebookIcon from 'modules/WebBuilder-V2/shared/icons/Facebook';
import LinkedinIcon from 'modules/WebBuilder-V2/shared/icons/Linkedin';
import TwitterIcon from 'modules/WebBuilder-V2/shared/icons/Twitter';
import YoutubeIcon from 'modules/WebBuilder-V2/shared/icons/Youtube';
import { ReactComponent as InstagramIcon } from 'assets/images/webBuilder/instagram-colorful.svg'
import { ReactComponent as TiktokIcon } from 'assets/images/webBuilder/tiktok-colorful.svg'
import { ReactComponent as SnapchatIcon } from 'assets/images/webBuilder/snapchat-colorful.svg'
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { logoURL } from 'helpers/utils';
import { useSBSState } from 'context/global';
import Locale from 'translations';

const currentYear = new Date().getFullYear();


export default function FooterThemeSeven() {
  const { footer: footerDetails, hero, ourServices } = useWebBuilderState();
  const { locale } = useSBSState();
  const { webBuilder } = Locale;

  const services = ourServices?.services?.filter(service => service?.visible);
  const header = hero?.header;
  const firstSlide = hero?.mainContent?.sliders[0];


  const socialMediaIcons = {
    facebook: <FacebookIcon />,
    twitter: <TwitterIcon />,
    youtube: <YoutubeIcon />,
    instagram: <InstagramIcon />,
    linkedin: <LinkedinIcon />,
    snapchat: <SnapchatIcon />,
    tiktok: <TiktokIcon />
  }


  const scrollToSection = (id) => {
    if (id) {
      const element = document.getElementById(id)
      element?.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <div className="theme_footer_7">
      <img
        className="footer_bg"
        src={firstSlide?.src || firstSlide || "https://back-sbs.staging.safa.asia/api/v1/files/fetch/b1df907a-9962-43c4-8708-1febec5895f4"}
        alt="Safa Business Solutions"
      />
      <div className="footer_content">
        <div className="logo_container">
          <Link to="/">
            <img
              className="footer-photo"
              src={logoURL(header?.logo, header?.logo?.[locale])}
              alt="Logo"
            />
          </Link>
        </div>

        <div className="footer_text">
          {footerDetails?.footerText[locale]}
        </div>
        <div className="info_container">
          <div className="social_media">
            <p className="text">
              {webBuilder.followUsOn} :
            </p>
            <div>
              <ul className="links">
                {footerDetails?.links?.map((link) => {
                  if (link?.visible) {
                    return (
                      <div key={link?.id}>
                        <a href={link?.url} target="_blank" rel="noreferrer">
                          {socialMediaIcons[link?.id]}
                        </a>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </ul>
            </div>
          </div>
          {header?.phoneNumber &&
            <div className="contact_phone">
              <p className="text">{webBuilder.contactUs} : </p>
              <p className="phone">
                <a href={`tel:${header?.phoneNumber}`}>
                  {header?.phoneNumber}
                </a>
              </p>
            </div>
          }
        </div>
        <ul className="footer_links">
          {services && services.length > 0 && services?.map(service => {
            return (
              <li className="pointer"
                onClick={() => scrollToSection(service?.id)}
              >
                {/* <a href="/">Hotels</a> */}
                < span > {service?.header[locale]}</span>
              </li>
            )
          })}
        </ul>
        <div className="footer-copy-right d-flex justify-content-center align-items-center">
          <p className="footer-text mt-1">
            {" "}
            {webBuilder.allRightsReserved} {" - "}
            <a
              href="https://safasoft.com/"
              target="_blank"
              rel="noreferrer"
              className="text-white"
            >
              {webBuilder.safasoft}
            </a>{" "}
            © {currentYear}
          </p>
        </div>
      </div>
    </div>
  )
}