import { useSBSState } from "context/global";
import { useWebBuilderState } from "context/webBuilder";
import { calcExchangeRatePrice } from "helpers/utils";
import React from "react";
import { Link } from "react-router-dom";
import Locale from "translations";
import Stars from "./Stars";
import MapMarkerIcon from "components/shared/MapMarker";

export const ServiceCardDetails = (props) => {
  const { exchageRates } = useWebBuilderState();
  const { currency: currentCurrency } = useSBSState();
  const {
    onClick,
    image,
    name,
    product_uuid,
    itemType,
    price,
    currency,
    stars,
    locationName,
    city,
  } = props;
  const { webBuilder } = Locale;

  let exchageRate = exchageRates?.find((rate) => rate?.from === currency)?.rate;
  let exchagedPrice = calcExchangeRatePrice(exchageRate, +price);

  return (
    <Link to={product_uuid ? `/${itemType}/${product_uuid}` : "/"}>
      <article
        className="service-card-details pointer"
        style={{ backgroundImage: `url(${image})` }}
      >
        <div onClick={onClick} className="service-card-details-name">
          <h5 className="fw-700 mb-2">{name}</h5>
          <div className="meta d-flex justify-content-between flex-wrap gap-4">
            <div className="location d-flex align-items-center gap-5">
              <MapMarkerIcon
                width="16"
                height="16"
                color="#000000"
                lineHeight="16"
              />
              <span>{locationName + "/" + city}</span>
            </div>
            <div className="rating">
              <Stars
                rate={stars}
                fillColor="#ffc107"
                unfillColor="#000000"
                with="16"
                height="16"
              />
            </div>
          </div>
          {price ? (
            <div className="start-price">
              <span>{webBuilder.from}</span>
              <span className="amount">
                {exchagedPrice}{" "}
                {exchageRate
                  ? Locale?.currency?.[currentCurrency]
                    ? Locale?.currency?.[currentCurrency]
                    : currency
                  : ""}
              </span>
            </div>
          ) : null}
        </div>
      </article>
    </Link>
  );
};
