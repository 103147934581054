import ServicesContent from "modules/WebBuilder-V2/shared/ServicesContent";
import ToursCarouselOverLayView from "./ToursCarouselOverLayView";
import ToursCarouselView from "./ToursCarouselView";
import { ServiceCardDetails } from "modules/WebBuilder-V2/shared/ServiceCardDetails";
import ToursTheme4 from "./ToursTheme4";
import ToursTheme7 from "./Theme7";

function ToursService({ details }) {
  const { id, body, title, items, tours_type } = details;

  function renderToursViews() {
    return {
      carousel: (
        <ToursCarouselView
          items={items?.filter((item) => item?.product_uuid)}
        />
      ),
      "carousel-overlay": (
        <ToursCarouselOverLayView
          items={items?.filter((item) => item?.product_uuid)}
        />
      ),
      "theme-3": (
        <div className="slider-edit-container">
          {items.map((props, index) => (
            <ServiceCardDetails
              {...props}
              itemType="tour"
              key={props?.id || index}
            />
          ))}
        </div>
      ),
      "theme-4": (
        <ToursTheme4
          items={items?.filter((item) => item?.product_uuid)}
        />
      ),
      "theme-7": (
        <ToursTheme7
          items={items?.filter((item) => item?.product_uuid)}
          title={title}
          body={body}
        />
      ),
    };
  }

  return (
    <>
      {items?.filter((item) => item?.product_uuid)?.length > 0 ? (
        <div className="web-builder-content-tours custom-container" id={id}>

          {tours_type !== "theme-7" &&
            <div className="service-top-section">
              <ServicesContent header={title} body={body} />
            </div>
          }

          <div className="tours-details services-carousel-container position-relative">
            {renderToursViews()[tours_type]}
          </div>
        </div>
      ) : null}
    </>
  );
}

export default ToursService;
