import React from "react";
import { ReactComponent as AddIcon } from "assets/images/webBuilder/add-square40.svg";
import { ReactComponent as StarIcon } from "assets/images/webBuilder/star.svg";
import { ReactComponent as GoldenStarIcon } from "assets/images/webBuilder/golden-star.svg";
import { ReactComponent as LocationIcon } from "assets/images/webBuilder/location-outline-icon.svg";
import { useHistory } from "react-router-dom";
import Locale from "translations";
import { calcExchangeRatePrice } from "helpers/utils";
import { useWebBuilderState } from "context/webBuilder";
import { useSBSState } from "context/global";

export default function ServiceCardWithButton(props) {
  const { exchageRates } = useWebBuilderState();
  const { currency: currentCurrency } = useSBSState();
  const { webBuilder } = Locale;
  const history = useHistory();
  let {
    openEditModal,
    name,
    price,
    image,
    currency,
    stars,
    isNew,
    cardType,
    product_uuid,
    initialName,
    locationName,
  } = props;
  const trimName = name
    ? name?.length > 22
      ? `${name?.substr(0, 22)}...`
      : name
    : initialName;
  const hotelRating = +stars > 5 ? 5 : +stars;

  let exchageRate = exchageRates?.find(rate => rate?.from === currency)?.rate;
  let exchagedPrice = calcExchangeRatePrice(exchageRate, +price)

  const preview = true;

  return (
    <article
      className={`${cardType}-carousel-card service-card-with-btns`}
      key={product_uuid}
    >
      <div className="card-details">
        <div className={`card-img ${isNew ? " empty-service" : ""}`}>
          {image ? (
            <img src={image} alt={trimName} />
          ) : isNew && !preview ? (
            <button
              className="btn add-service-btn"
              onClick={() => openEditModal(props)}
            >
              <AddIcon />
            </button>
          ) : null}
        </div>
        <div className="card-content">
          <h4 className="service-name" title={name}>
            {trimName}
          </h4>
          {cardType === "hotels" && (
            <div className="rate mb-2">
              {[...Array(5)].map((i, index) =>
                index < hotelRating ? (
                  <GoldenStarIcon key={index} />
                ) : (
                  <StarIcon key={index} />
                )
              )}
            </div>
          )}
          <div className="location mt-2">
            <LocationIcon />
            <span>{locationName}</span>
          </div>
        </div>
        <div className="card-footer">
          <div>
            <span className="font-grey-light">{webBuilder.startFrom}</span>
            <div className="d-flex align-items-center">
              <p className="amount me-1"> {exchagedPrice} </p>
              <div>
                <span className="currency">{exchageRate ? currentCurrency : currency || ""}</span>
                {cardType !== "tours" &&
                  <span className="font-grey-light">/{webBuilder.night}</span>
                }
              </div>
            </div>
          </div>
          <button
            className="btn web-builder-filled-btn"
            onClick={() => {
              if (product_uuid) {
                if (preview) {
                  if (true) {
                    history.push(`/${cardType}/${product_uuid}`);
                  } else {
                    history.push(
                      `/web-builder-v2/preview/${cardType}/${product_uuid}`
                    );
                  }
                }
              }
            }}
          >
            {webBuilder.bookNow}
          </button>
        </div>
      </div>
    </article>
  );
}
