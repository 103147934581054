import ServicesContent from "modules/WebBuilder/shared/ServicesContent";
import FlightServiceCard from "./FlightCard";
import { useWebBuilderState } from "context/webBuilder";
import FlightServiceCardTheme4 from "./Theme4Card";
import ThemeSevenFlights from "./theme7";

function FlightsService({ details }) {
  const { style } = useWebBuilderState();
  const { id, body, title, image, items, items_per_row } = details;

  return (
    <section className="flight-section">
      {items?.filter((item) => item?.product_uuid)?.length > 0 ? (
        <div className="web-builder-content-flights custom-container" id={id}>
          {style.theme === "theme-7" ?
            <div className="theme_flight_section">
              <ThemeSevenFlights
                items={items}
                title={title}
                body={body}
                image={image}
              />
            </div>
            :
            <>
              <div className="service-top-section">
                <ServicesContent header={title} body={body} />
              </div>
              <div
                className="flights-cards-container"
                style={{
                  gridTemplateColumns: `repeat(${+items_per_row < items?.length ? items_per_row : items?.length
                    } , 1fr)`,
                }}
              >
                {items.map((item, index) => {
                  if (style.theme === "theme-4") {
                    return (
                      <FlightServiceCardTheme4
                        {...item}
                        itemType="flight"
                        key={item.id || `flight-${index}`}
                      />
                    );
                  } else {
                    return (
                      <FlightServiceCard
                        {...item}
                        itemType="flight"
                        key={item.id || `flight-${index}`}
                      />
                    );
                  }
                })}
              </div>
            </>
          }
        </div>
      ) : null}
    </section>
  );
}

export default FlightsService;
