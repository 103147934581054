import ServicesContent from "modules/WebBuilder-V2/shared/ServicesContent";
import React from "react";
import HotelGridView from "./HotelGridView";
import HotelCarouselView from "./HotelCarouselView";
import { ServiceCardDetails } from "modules/WebBuilder-V2/shared/ServiceCardDetails";
import HotelTheme4 from "./HotelTheme4";
import HotelTheme7 from "./Theme7";

function HotelService({ details }) {
  let { id, type, title, body, items, hotel_type } = details;
  items = items?.filter((item) => item?.product_uuid);
  function renderHotelView() {
    return {
      grid: (
        <HotelGridView
          items={items}
          type={type}
        />
      ),
      carousel: (
        <HotelCarouselView
          items={items}
          type={type}
        />
      ),
      "theme-3": (
        <div className="slider-edit-container">
          {items.map((props, index) => (
            <ServiceCardDetails
              {...props}
              itemType="hotel"
              key={props?.id || index}
            />
          ))}
        </div>
      ),
      "theme-4": (
        <HotelTheme4
          items={items}
          type={type}
        />
      ),
    };
  }

  return (
    <>
      {items?.length > 0 ? (
        <>
          {hotel_type !== "theme-7" ?
            <div className="web-builder-content-hotels custom-container" id={id}>
              <div className="service-top-section">
                <ServicesContent header={title} body={body} />
              </div>

              <div className="hotels-detials services-carousel-container position-relative">
                {items && renderHotelView()?.[hotel_type]}
              </div>
            </div>
            :
            <HotelTheme7
              id={id}
              items={items}
              title={title}
              body={body}
            />
          }
        </>
      ) : null}
    </>
  );
}

export default HotelService;
